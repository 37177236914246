import React, { useState } from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import "./contact.css";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import Axios from "axios";

export default () => {
    return <>
        <Helmet title="お問い合わせ | VISUALIZ INC" />
        <Layout>
            <Main />
        </Layout>
    </>;
};

const Main = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);


    const { register, handleSubmit, getValues, } = useForm();

    const values = getValues();

    const handle = handleSubmit(e => {
        setPage(1);
    });

    const send = async () => {
        await Axios.post("https://visualiz-cms1.azurewebsites.net/api/contacts/f9a91408-5994-4a9a-af38-5305007f6916",
            {
                name: values["firstName"] + " " + values["secondName"],
                ruby: values["firstNameRuby"] + " " + values["secondNameRuby"],
                company: values["company"],
                email: values["email"],
                title: values["title"],
                content: values["content"],
                system: "visualiz-official",
            });
        setPage(2);
    };

    return (
        <main>
            <PageSummary
                title={t("お問い合わせ")}
                subTitle={t("CONTACT")}
                description={
                    <>
                        {t("お問い合わせ・取材申し込み")}<br />
                        {t("ご意⾒・ご要望などこちらからお問い合わせください")}
                    </>
                }
            />

            <Box mx="auto" mb={4} maxWidth={"400px"}>
                <Typography variant="body1" textAlign={"left"}>
                    様々なお問い合わせやご質問はお⼿数ですが<br />
                    <Link to="/privacypolicy">プライバシーポリシー</Link>をご確認のうえ、本ページにご⼊⼒ください。<br />
                    弊社担当から後程ご連絡させていただきます。
                </Typography>
            </Box>

            <Box mt={12} />

            {page === 0 && <form onSubmit={handle} >
                <div>
                    <label htmlFor="name">※必須：お名前</label>
                    <div className="Form01">
                        <input type="text" id="name" placeholder="姓" required {...register("firstName")} />
                        <input type="text" id="name" placeholder="名" required {...register("secondName")} />
                    </div>
                </div>
                <div>
                    <label htmlFor="kana">ふりがな</label>
                    <div className="Form02">
                        <input type="text" id="kana" placeholder="せい" {...register("firstNameRuby")} />
                        <input type="text" id="kana" placeholder="めい" {...register("secondNameRuby")} />
                    </div>
                </div>
                <div>
                    <label htmlFor="email">※必須：ご連絡先</label>
                    <div className="Form03">
                        <input type="email" id="email" placeholder="返信用メールアドレス" required {...register("email")} />
                    </div>
                </div>
                <div>
                    <label htmlFor="workp">※必須：会社名</label>
                    <div className="Form04">
                        <textarea id="workp" placeholder="差し⽀えなければご記⼊ください" required {...register("company")}></textarea>
                    </div>
                </div>
                <div>
                    <label htmlFor="title">※必須：タイトル</label>
                    <div className="Form05">
                        <textarea id="title" placeholder="タイトルをご記⼊ください" required {...register("title")}></textarea>
                    </div>
                </div>
                <div>
                    <label htmlFor="message">※必須：お問い合わせ内容</label>
                    <div className="Form06">
                        <textarea id="message" placeholder="お問い合わせの内容をご記⼊ください" required {...register("content")}> </textarea>
                    </div>
                </div>
                <div>
                    <Button type="submit" >{t("確認")}</Button>
                </div>
            </form>
            }
            {
                page === 1 && <>
                    <Box mt={2}>
                        <Typography>{values["firstName"]}{values["secondName"]}</Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography>{values["firstNameRuby"]}{values["secondNameRuby"]}</Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography>{values["company"]}</Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography>{values["email"]}</Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography>{values["title"]}</Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography>{values["content"]}</Typography>
                    </Box>

                    <Box mt={3}>{t("以上の内容で送信します")}</Box>

                    <Box>
                        <Button type="submit" onClick={send}>{t("送信")}</Button>
                    </Box>
                </>
            }
            {
                page === 2 && <>
                    <Box mt={2}>
                        <Typography>ありがとうございました。</Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography>数営業日以内に担当者からご連絡をさせていただきます。</Typography>
                    </Box>
                </>
            }

            <Box mt={12} />
        </main>
    );
};
